@keyframes spin { 100% { -webkit-transform: rotate(360deg);
  transform: rotate(360deg); } }

.re-spinner-backdrop {
  background-color: white;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1999;
}

.re-spinner-circle,
.re-spinner-circle:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.re-spinner-circle {
  background-color: white;
  font-size: 6px;
  border-width: 4px;
  border-top: 1.1em solid;
  border-left: 1.1em solid;
  border-right: 1.1em solid;
  border-bottom: 1.1em solid;
  border-color: rgb(238, 233, 233) rgb(238, 233, 233) rgb(238, 233, 233) rgb(139, 137, 137);
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: auto!important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
}

.re-spinner-fullscreen {
  position: fixed;
  position: -ms-page;
}

.re-spinner-validation {
  width: 30px;
  height: 30px;
  border-width: 4px;
}
